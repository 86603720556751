<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import AddForm from './add_form.vue';

formCreate.component('AddForm', AddForm);

export default {
  name: 'NeedGoodsForm',
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      addData: [{
        customerCode: '',
        companyName: '',
        companyCode: '',
        id: new Date().getTime(),
      },
      ],
      data: null,
      formFunctionCode: 'need_goods_config_add',
      formParentCode: 'CRM20220310000000002',
    };
  },

  methods: {
    submit() {
      const formData = this.getFormData();
      const detailVoList = this.fApi.method('addForm', 'getFormData')();
      if (detailVoList === 'noCheck') {
        return;
      }
      formData.detailVoList = detailVoList;
      if (this.formConfig.code === 'edit') {
        formData.id = this.formConfig.row.id;
      }

      console.log('数据', formData);
      if (formData) {
        const url = '/dms/orderConfig/save';
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
            // this.$emit('resetTree');
          }
        });
      }
    },
    setRule(item) {
      const rowData = item;
      if (rowData.field === 'addForm') {
        rowData.props.positionData = [];
      }
      return rowData;
    },
    formComplete() {
      const addForm = this.getRule('addForm');
      if (this.formConfig.code === 'edit') {
        request.get('/dms/orderConfig/query', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            console.log('编辑接口', res.result);
            this.addData = res.result.detailVoList;
            this.setValue(res.result);
            addForm.props = {
              ...addForm.props,
              positionData: res.result.detailVoList,
            };
          }
        });
      }
      const companyName = this.getRule('companyName');
      companyName.restful = '/mdm/companyInfo/companyPageList';

      companyName.props = {
        ...companyName.props,
        filterable: true,
        remote: true,
        remoteParams: 'companyName',
      };
      companyName.optionsKey = {
        label: 'companyName',
        value: 'companyCode',
      };
      companyName.on = {
        ...companyName.on,
        getItem: (val) => {
          this.setValue({ companyName: val.companyName, companyCode: val.value });
        },
      };

      addForm.on = {
        del: (index) => {
          this.addData.splice(index, 1);
          console.log('del', this.addData);
        },
      };
      // 增加
      const title = this.getRule('title');
      if (this.formConfig.code !== 'view') {
        title.props.showAdd = true;
      }
      addForm.props = {
        ...addForm.props,
        positionData: this.addData,
      };
      title.on = {
        add: () => {
          this.addData.push({
            customerCode: '',
            companyName: '',
            companyCode: '',
            id: new Date().getTime(),
          });

          addForm.props = {
            ...addForm.props,
            positionData: this.addData,
          };
          console.log('addForm规则', addForm);
        },
      };
    },
  },
  created() {
  },
};

</script>
