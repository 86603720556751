var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.positionData, function (item, index) {
      return _c(
        "div",
        { key: item.id, staticClass: "sale-area-container" },
        [
          _c("AddFormMsg", {
            ref: item.id,
            refInFor: true,
            staticClass: "sale-area-item",
            attrs: { addData: item },
          }),
          index > 0
            ? _c("el-button", {
                staticClass: "del-btn",
                attrs: { type: "text", icon: "el-icon-delete" },
                on: {
                  click: function ($event) {
                    return _vm.del(index)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }