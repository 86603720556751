<template>
  <div>

    <div class="sale-area-container" v-for="(item,index) in positionData" :key="item.id">
           <AddFormMsg :ref="item.id" class="sale-area-item" :addData="item" />
    <!-- <AddFormMsg :showProduct="showProduct" :fieldDisabled="disabled" :ref="item.id" class="sale-area-item" :addData="item"/> -->
   <el-button v-if="index>0" class="del-btn"  type="text" @click="del(index)" icon="el-icon-delete"></el-button>
    </div>
  </div>
</template>

<script>
import AddFormMsg from './add_form_msg.vue';

export default {
  components: {
    AddFormMsg,
  },
  props: {

    positionData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    positionData(newVal, oldVal) {
      this.positionData = newVal;
      console.log('监听到了数据', this.positionData);
    },
  },
  created() {
  },
  methods: {
    del(index) {
      this.$emit('del', index);
    },
    getFormData() {
      const data = [];
      let flag = 'check';
      this.positionData.forEach((v) => {
        if (this.$refs[v.id][0].getFormData()) {
          data.push(this.$refs[v.id][0].getFormData());
        } else {
          flag = 'noCheck';
        }
      });
      if (flag === 'check') {
        return data;
      }
      return 'noCheck';
    },
  },
};
</script>

<style lang="less" scoped>
  .sale-area-container{
    // display: flex;
    // align-items: top;
    position: relative;
    border-bottom: 1px solid #eee;
    padding-top:20px;

    .del-btn{
      position: absolute;
      top: 0;
      right: -16px;
      width: 20px;
      color: red;
    }
  }
</style>
