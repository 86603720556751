<script>
import Form, { formCreate } from '@/found/components/form';

export default {
  components: {
    formCreate: formCreate.$form(),
  },
  extends: Form,
  props: {
    addData: Object,
  },
  watch: {
    addData(val) {
      this.addData = val;
    },
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
      },
      rule: [
        {
          title: '公司主体',
          type: 'select',
          field: 'companyCode',
          required: true,
          options: [],
          value: this.addData.companyCode,
          restful: '/mdm/companyInfo/companyPageList',
          optionsKey: {
            label: 'companyName',
            value: 'companyCode',
          },
          on: {
            getItem: (val) => {
              console.log(val);
              this.setValue({ companyName: val.companyName });
            },
          },
          props: {
            filterable: true,
            remote: true,
            clearable: true,
            remoteParams: 'companyName',
          },
          col: {
            span: 8,
          },
        },
        {
          title: '客户',
          type: 'select',
          field: 'customerCode',
          required: true,
          options: [],
          value: this.addData.customerCode,
          restful: '/mdm/companyInfo/companyPageList',
          optionsKey: {
            label: 'companyName',
            value: 'insideCustomerCode',
          },
          on: {
            getItem: (val) => {
              console.log(val);
              this.setValue({ customerName: val.companyName });
            },
          },
          props: {
            filterable: true,
            // disabled: this.fieldDisabled,
            remote: true,
            clearable: true,
            remoteParams: 'companyName',
          },
          col: {

            span: 8,

          },
        },
        {
          title: '公司主体',
          type: 'select',
          field: 'companyName',
          value: this.addData.companyName,
          hidden: true,
        }, {
          title: '客户公司名称',
          type: 'select',
          field: 'customerName',
          value: this.addData.customerName,
          hidden: true,
        },
      ],
    };
  },
  mounted() {
  },
  methods: {},
  created() {
    console.log('-dsadas--->', this.addData);
  },
};
</script>
